<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-card>
        <b-row>
          <b-col cols="6">
            <h4 v-show="filtre">
              {{ $store.getters.yil }} Yılı {{ $store.getters.ay }} Ayı Oda
              Bazlı Konaklama Raporu
            </h4>
          </b-col>
          <b-col cols="6" class="text-right">
            <v-select v-model="year" :options="years"/>
            <v-select v-model="month" :options="months" class="ml-50 mr-1"/>
            <!--            <b-button-->
            <!--                v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
            <!--                variant="info"-->
            <!--                to="/"-->
            <!--                class="white"-->
            <!--            >-->
            <!--              <feather-icon icon="PrinterIcon" class="mr-50"/>-->
            <!--              Dashboard-->
            <!--            </b-button>-->
          </b-col>
        </b-row>
        <hr/>
        <b-row>
          <b-col cols="12">
            <h5>
              <small>
                <b>
                  <i style="color: rgb(203, 0, 19)">Oda Kapatma</i>,
                  <i style="color: rgb(102, 16, 242)"> Full Oda</i>,
                  <i style="color: rgb(11, 84, 123)"> Rezervasyon Mevcut </i>
                </b>
              </small>
            </h5>
          </b-col>
        </b-row>
        <b-row v-for="(oda, index) in $store.getters.odalar" :key="index">
          <b-col cols="12">
            <strong>{{ oda.odaKodu }} Nolu Oda </strong>
            <table class="table table-borderless">
              <thead>
              <tr>
                <th
                    scope="col"
                    v-for="(item, index) in $store.getters.aySonuRez"
                    :key="index"
                >
                  {{ item }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td
                    v-for="(day, index) in $store.getters.aySonuRez"
                    :key="index"
                    class="p-0"
                    style="
                      padding: 0px !important;
                      background-color: #c8d8d2;
                      color: black !important;
                    "
                    :style="{
                      cursor: durum(day, oda.odaKodu) ? 'pointer' : 'default',
                      backgroundColor:
                        durum(day, oda.odaKodu).odaKapatma == 1
                          ? '#fc4b6c'
                          : parseInt(oda.odaYatak) + parseInt(oda.odaEkYatak) ==
                            isDurumFullOda(day, oda.odaKodu)
                          ? '#7460ee'
                          : durum(day, oda.odaKodu)
                          ? '#0b547b'
                          : !durum(day, oda.odaKodu) &&
                            isDateControl(day) < isDate()
                          ? '#c8d8d2'
                          : isDateControl(day) < isDate()
                          ? 'black'
                          : '#c8d8d2',
                    }"
                >
                  <!--                  @click="rezShow(day, oda)"-->
                  <!--                  v-b-modal.show-rez-->
                  <div
                      class="rez-show"
                      v-if="durum(day, oda.odaKodu)"
                  >
                    <!--                    <feather-icon icon="SearchIcon" class="text-white"/>-->
                  </div>
                  <div class="rez-show" v-if="!durum(day, oda.odaKodu)"/>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal
        id="show-rez"
        centered
        size="xl"
        header-bg-variant="white"
        hide-footer
    >
      <b-row>
        <b-col cols="12" class="mb-3">
          <h1 class="text-center">Rezervason Bilgileri</h1>
        </b-col>
      </b-row>
      <table class="table modal-thead-table">
        <thead class="modal-thead">
        <tr>
          <th scope="col">KONUK BİLGİLERİ</th>
          <th scope="col">GİRİŞ</th>
          <th scope="col">ÇIKIŞ</th>
          <th scope="col">KONAKLAMA</th>
          <th scope="col">GÜNLÜK ÜCRET</th>
          <th scope="col">TOPLAM ÜCRET</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(rezervasyon, index) in this.$store.state.rezervasyon
              .rezervasyonKonukListesi"
            :key="index"
        >
          <td>
            <div class="d-flex align-items-center">
              <b-avatar
                  class="pull-up mr-2"
                  :style="{
                    backgroundColor:
                      rezervasyon.cinsiyet == 1
                        ? $store.getters.bgInfo
                        : $store.getters.bgDanger,
                  }"
                  :text="
                    rezervasyon.adi.charAt(0) +
                    '.' +
                    rezervasyon.soyAdi.charAt(0)
                  "
                  badge=""
                  badge-variant="success"
                  variant="primary"
              />
              <div class="d-flex flex-column">
                  <span class="fw-bolder userAdi">
                    {{ rezervasyon.adi }} {{ rezervasyon.soyAdi }}
                    <span
                        style="
                        background-color: #6f3131 !important;
                        color: white !important;
                      "
                        class="badge badge-light-danger onRezBadge"
                        v-if="rezervasyon.onlineRezervasyon == 1"
                    >Online Rezervasyon</span
                    >
                    <span
                        style="
                        background-color: #696f31 !important;
                        color: white !important;
                      "
                        class="badge badge-light-danger onRezzBadge"
                        v-if="rezervasyon.onRezervasyon == 1"
                    >Ön Rezervasyon</span
                    >
                    <span
                        style="background-color: #696f31 !important"
                        class="badge badge-bgWarning cikisRezBadge"
                        v-if="rezervasyon.checkOut == 1"
                    >Çıkış Yapıldı</span
                    >
                  </span>
                <small class="emp_post text-muted myTelBadge">
                    <span class="badge badge-light-primary smallBadge">{{
                        rezervasyon.tarifeText
                      }}</span>
                  <span class="badge badge-light-primary smallBadge"
                  >TC:{{ rezervasyon.tcKimlik }}</span
                  >
                  <b-badge variant="primary" class="badge-light-primary">
                    <feather-icon icon="PhoneIcon" class="mr-25"/>
                    <span>{{ rezervasyon.tel }}</span>
                  </b-badge>
                </small>
              </div>
            </div>
          </td>
          <td class="text-center">
            <b-button
                v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                variant="flat-primary"
                class="inputTarih"
                :disabled="true"
            >
              {{ rezervasyon.girisTarih }}
            </b-button>
          </td>
          <td class="text-center">
            <b-button
                v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                variant="flat-primary"
                class="inputTarih"
                style="white-space: nowrap"
                :disabled="true"
            >
              {{ rezervasyon.cikisTarih }}
            </b-button>
          </td>
          <td class="text-center">
            <b-badge pill variant="cardTitle-L" class="pVerilcek"
            >{{ rezervasyon.period }} Gece
            </b-badge
            >
          </td>
          <td class="text-center">
            <b-badge
                class="gunluk-ucret"
                style="cursor: default"
                href="javascript:void(0)"
                pill
                variant="cardTitle-L"
                :disabled="true"
            >
              {{ rezervasyon.fiyat }} TL
            </b-badge>
          </td>
          <td class="text-center">
            <b-badge pill variant="cardTitle-L" class="pVerilcek"
            >{{ rezervasyon.period * rezervasyon.fiyat }} TL
            </b-badge>
          </td>
          <td>
            <b-button
                variant="info"
                class="rez-go-btn"
                :to="
                  '/rezervasyon/' +
                  $store.state.rezervasyon.rezervasyonBilgileri.rezervasyonNo
                "
                size="sm"
            >
              <feather-icon icon="LogInIcon" class="mr-50"/>
              <span class="align-middle">Rezervasyona Git</span>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BAvatar,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BAvatar,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BModal,
    VBModal,
  },
  data() {
    return {
      spinner: true,
      showRez: [],
      selected: [],
      adi: '',
      soyAdi: '',
      tcKimlik: '',
      telefon: '',
      fiyat: '',
      aciklama: '',
      tarife: {
        title: 'Üye',
        id: 3
      },
      tarifeOps: [
        {
          title: 'Üye',
          id: 3,
        },
        {
          title: 'Kamu',
          id: 1,
        },
        {
          title: 'Sivil',
          id: 2,
        },
      ],
      cinsiyet: {
        title: 'Erkek',
        id: 1
      },
      cinsiyetOps: [
        {
          title: 'Erkek',
          id: 1,
        },
        {
          title: 'Kadın',
          id: 0,
        },
      ],
      odaKapatma: {
        title: 'Hayır',
        id: 0
      },
      odaKapatmaOps: [
        {
          title: 'Hayır',
          id: 0,
        },
        {
          title: 'Evet',
          id: 1,
        },
      ],
      years: [2019, 2020, 2021, 2022, 2023, 2024, 2025],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      year: 2022,
      month: 'Ocak',
      filtre: false,
    }
  },
  watch: {
    year(newVal) {
      const ay = this.ayGets()
      this.$store.dispatch('odaRaporYear', {
        year: newVal,
        month: ay,
      })
    },
    month(newVal) {
      this.$store.dispatch('odaRaporMonth', {
        year: this.year,
        month: newVal,
      })
    },
  },
  methods: {
    rezShow(index, rez) {
      const { konuklar } = this.$store.getters
      if (konuklar[index][rez.odaKodu]) {
        const show = konuklar[index][rez.odaKodu].rezervasyonBilgileri
        const rezNo = Object.values(show)[0].rezervasyonNo
        this.$store.dispatch('rezervasyonGo', rezNo)
            .then(res => {
              this.showRez = res.rezervasyonKonukListesi
            })
      }
    },
    isDateControl(day) {
      day = String(day)
          .padStart(2, '0')
      const yil = this.year
      const ay = this.ayGets()
      return yil + '/' + ay + '/' + day
    },
    isDate() {
      var today = new Date()
      var dd = String(today.getDate())
          .padStart(2, '0')
      var mm = String(today.getMonth() + 1)
          .padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()
      return yyyy + '/' + mm + '/' + dd
    },
    dayCL(day) {
      return (day = String(day)
          .padStart(2, '0'))
    },
    ayGets() {
      switch (this.month) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break

        default:
          break
      }
    },
    durum(index, odaNo) {
      let konuklar = this.$store.getters.konuklar
      if (konuklar[index][odaNo]) {
        if (konuklar[index][odaNo]['rezervasyonBilgileri']) {
          for (let [key, value] of Object.entries(
              konuklar[index][odaNo]['rezervasyonBilgileri']
          )) {
            return value
          }
        }
      } else {
        return false
      }
    },
    isDurumFullOda(index, odaNo) {
      let konuklar = this.$store.getters.konuklar
      if (konuklar[index][odaNo]) {
        if (konuklar[index][odaNo]['rezervasyonBilgileri']) {
          for (let [key, value] of Object.entries(
              konuklar[index][odaNo]['rezervasyonBilgileri']
          )) {
            return Object.keys(konuklar[index][odaNo]['rezervasyonBilgileri'])
                .length
          }
        }
      } else {
        return false
      }
    },
    ayGetTurkishType(ay) {
      switch (ay) {
        case '01':
          return 'Ocak'
          break
        case '02':
          return 'Şubat'
          break
        case '03':
          return 'Mart'
          break
        case '04':
          return 'Nisan'
          break
        case '05':
          return 'Mayıs'
          break
        case '06':
          return 'Haziran'
          break
        case '07':
          return 'Temmuz'
          break
        case '08':
          return 'Ağustos'
          break
        case '09':
          return 'Eylül'
          break
        case '10':
          return 'Ekim'
          break
        case '11':
          return 'Kasım'
          break
        case '12':
          return 'Aralık'
          break

        default:
          break
      }
    },
  },
  computed: {
    ayGet() {
      switch (this.month) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break

        default:
          break
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('clearHizliRez')
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    const today = new Date()
    var month = String(today.getMonth() + 1)
        .padStart(2, '0')
    var yyyy = today.getFullYear()
    this.year = yyyy
    this.month = this.ayGetTurkishType(month)
    this.filtre = true
    this.$store
        .dispatch('odaRapor', {
          year: this.year,
          month,
        })
        .then((res, position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>

<style scoped>
.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}

.cikisRezBadge {
  width: 85px;
}

.rez-show .feather {
  transform: translate(16px, 5px);
}

.rez-show {
  min-height: 32px;
}

label input[id="customCheck"] ~ span {
  display: inline-block;
  vertical-align: middle;
  height: 32px !important;
  width: 50px !important;
  position: absolute;
  margin-top: -18px !important;
  margin-left: -15px;
}

input[id="customCheck"]:checked ~ div label {
  /* background: url(http://ramyasspace.files.wordpress.com/2011/06/tick.jpg); */
  background: url(https://cdn-icons-png.flaticon.com/512/1828/1828643.png);
  background-size: 100% !important;
  background-position-y: top !important;
}

#customCheck {
  display: none;
}

label input[id="customCheck"] ~ span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* background: #fff; */
  border: 1px solid #888;
  padding: 1px;
  height: 32px !important;
  width: 50px !important;
}

label input[type="checkbox"]:checked ~ span {
  background: url("https://img.icons8.com/nolan/344/checkmark.png") !important;
  background-size: 100% !important;
  background-position-y: -8px !important;
  background-color: #2196f3 !important;
}

.v-select {
  display: inline-block !important;
  width: 330px !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  width: 50px !important;
}

.row {
  justify-content: space-between;
}

.chck {
  border-right: 1px solid white !important;
}

.container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

[dir] .btn-secondary {
  border-color: #82868b !important;
  background-color: #82868b !important;
}

.v-select {
  display: inline-block !important;
  width: 400px;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

table {
  border: 1px solid grey;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.modal-thead {
  height: 35px !important;
}

.modal-thead th {
  vertical-align: middle !important;
}

.modal-thead-table {
  border: none !important;
}

.rez-go-btn,
.gunluk-ucret {
  color: white !important;
}

.rez-go-btn :hover {
  color: white !important;
}

.gunluk-ucret :hover a {
  color: white !important;
}

.onRezBadge {
  width: 130px;
  padding: 5px;
  margin-right: 4px;
}

.smallBadge {
  margin-right: 5px;
  font-weight: 500;
}

.onRezzBadge {
  width: 120px;
  padding: 5px;
}

.myTelBadge {
  margin-top: 5px;
}

.userAdi {
  font-weight: 900;
}

.white {
  color: white !important;
}

.v-select {
  width: 20% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
